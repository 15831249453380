<script>
  export let value = 0;
  export let connected = 0;
  export let torrentStatusClass = null;
</script>

{value}
<em>of</em>
{connected}

<style>
  em {
    font-size: 10px;
    font-style: normal;
  }
</style>
